import { NUMERIRIVISTE, RIVISTE, SLUG, ARTICOLI, UTENTE } from 'utils/endpoint'
import React, { useEffect, useState } from 'react'

import Error from 'next/error'
import MetaSeo from 'components/atomic/MetaSeo'
import { api, getBoxAbbonamento } from 'utils'
import RivistaDetailTemplate from 'react-components/templates/RivistaDetailTemplate/RivistaDetailTemplate'
import SesamoTemplate from 'react-components/templates/SesamoTemplate/SesamoTemplate'
import { useSelector } from 'react-redux'
import { sendProductImpressionGTM } from '../../utils/gtm'

const RivistaDetail = ({ rivista, ultimoNumero, page, statusCode }) => {
  if (!rivista) return <Error statusCode={statusCode} />

  const { utente } = useSelector((state) => state.utente)

  const [numeri, setNumeri] = useState([])
  const [risorse, setRisorse] = useState([])
  const [boxAbbonamento, setBoxAbbonamento] = useState([])

  useEffect(() => {
    initNumeri()
    initRisorse()
  }, [])

  useEffect(() => {
    initBoxAbbonamento()
  }, [utente])

  useEffect(() => {
    if (risorse.length) checkTools()
  }, [risorse.length])

  async function initNumeri() {
    const params = {
      page_size: 4,
      page: 1,
      rivista_id: rivista.id,
    }
    const response = await api.get(NUMERIRIVISTE, { params })
    const numeri = response.data.results
    sendProductImpressionGTM(numeri, 'archivio_carosello')
    setNumeri(numeri)
  }

  async function initRisorse() {
    const params = {
      in_rivista: true,
      numero_rivista__rivista_id: rivista.id,
    }

    const response = await api.get(ARTICOLI, { params })
    const risorse = response.data.results
    sendProductImpressionGTM(risorse, 'contenuti_correlati_carosello')
    setRisorse(risorse)
  }

  function initBoxAbbonamento() {
    setBoxAbbonamento(getBoxAbbonamento(rivista.box_abbonamenti, utente))
  }

  function checkTools() {
    const params = risorse.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    api.get(UTENTE + 'tools/', { params }).then((response) => {
      const tmp = risorse.map((item) => {
        const current = response.data.find(
          (e) => e.content_type_id === item.model && e.object_id === item.id
        )

        const { saved, following, file_url, pdf_file } = current

        return { extra: { ...item.extra, file_url, pdf_file }, saved, following, ...item }
      })

      setRisorse(tmp)
    })
  }

  const props = {
    rivista,
    risorse,
    numeri,
    ultimoNumero,
    boxAbbonamento,
    page,
  }

  return (
    <>
      <MetaSeo seo={page.seo} />
      {rivista.slug === 'sesamo' ? (
        <SesamoTemplate {...props} />
      ) : (
        <RivistaDetailTemplate {...props} />
      )}
    </>
  )
}

export async function getStaticPaths() {
  const response = await api.get(RIVISTE)
  const riviste = response.data

  const paths = riviste.map((r) => ({
    params: { slug: r.slug },
  }))

  return {
    paths,
    fallback: 'blocking',
  }
}

export const getStaticProps = async ({ params: { slug } }) => {
  try {
    const response = await api.get(`${RIVISTE}${SLUG}${slug}/`)
    const rivista = response.data

    const page = rivista.pagina

    const params = { rivista_id: rivista.id }
    const responseNumero = await api.get(`${NUMERIRIVISTE}latest/`, { params })
    const ultimoNumero = responseNumero.data

    const toolbarProps = { toolbarItem: rivista, toolbarPage: page }

    return {
      props: { rivista, page, ultimoNumero, key: rivista.id, ...toolbarProps },
      revalidate: 60,
    }
  } catch (error) {
    console.log(error)
    return {
      notFound: true,
    }
  }
}

export default RivistaDetail
