import React from 'react'
import { useTrans } from 'hooks'
import { Image } from 'react-components/atoms'
import { Button } from 'react-components/atoms'
import Breadcrumbs from 'components/atomic/Breadcrumbs'
import { CAS_DOMAIN } from 'utils'
import { BoxElementiRivista } from 'react-components/organisms'
import { BoxAbbonamento, NumeroRivistaCard } from 'react-components/molecules'
import { renderCardWithProps } from 'react-components/organisms/AreaPersonaleRaccoltaContenutiEditForm/AreaPersonaleRaccoltaContenutiEditForm'
import BannerInserto from 'react-components/molecules/BannerInserto/BannerInserto'
import { useRouter } from 'next/router'

const RivistaDetailTemplate = (props) => {
  const {
    page,
    rivista,
    risorse = [],
    numeri = [],
    ultimoNumero,
    boxAbbonamento = [],
    className = '',
  } = props

  const t = useTrans()
  const router = useRouter()
  return (
    <main className={`rivista-detail-template ${className ? ` ${className}` : ''}`}>
      <div className="w-container rivista-detail-template__breadcrumbs">
        <Breadcrumbs breadcrumbs={page.breadcrumbs} />
      </div>
      <div className="w-container rivista-detail-template__column-display">
        <h1 className="rivista-detail-template__title">{rivista.titolo}</h1>
        <div className="rivista-detail-template__image-container">
          <Image
            src={CAS_DOMAIN + rivista.preview_image}
            alt={rivista.titolo?.toAltTitle()}
            title={rivista.titolo?.toAltTitle()}
            width={210}
            height={60}
          />
        </div>
        {rivista.sottotitolo && (
          <p className="rivista-detail-template__subtitle">{rivista.sottotitolo}</p>
        )}
        {rivista.descrizione && (
          <p className="rivista-detail-template__description">{rivista.descrizione}</p>
        )}
      </div>
      <div className="rivista-detail-template__anchors">
        <Button
          className="rivista-detail-template__anchor"
          label={t("L'ultimo numero")}
          variant="ghost"
          href={'#ultimo-numero'}
        />
        <Button
          className="rivista-detail-template__anchor"
          label={t('Cosa contiene')}
          variant="ghost"
          href={'#cosa-contiene'}
        />
        {boxAbbonamento?.length > 0 && (
          <Button
            className="rivista-detail-template__anchor"
            label={t('Come abbonarsi')}
            variant="ghost"
            href={'#come-abbonarsi'}
          />
        )}
      </div>
      <div className="w-container" style={{ width: '100%' }}>
        {rivista.slug && rivista.slug == 'vita-scolastica' && rivista.inserto ? (
          <div className="rivista-detail-template__inserto rivista-detail-template__risorse">
            <BannerInserto
              title={t('BannerInsertoLandingVitaScolasticaTitleBottom')}
              text={t('BannerInsertoLandingVitaScolasticaTextBottom')}
              landingRivista={true}
              buttonVersion={true}
              buttonText={t("Scopri l'inserto")}
              buttonClickFunction={() => {
                router.push('/riviste/psicologia-e-scuola')
              }}
              imageSrc={CAS_DOMAIN + rivista.inserto?.main_image}
              width={90}
              height={120}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="rivista-detail-template__inserto rivista-detail-template__inserto--medium">
          {rivista.slug && rivista.slug == 'psicologia-e-scuola' ? (
            <BannerInserto
              title={t('BannerInsertoLandingPsicologiaEScuolaTitle')}
              text={t('BannerInsertoLandingPsicologiaEScuolaText')}
              landingRivista={true}
              iconVersion={true}
            />
          ) : (
            <></>
          )}
        </div>
        <div id="ultimo-numero" className="rivista-detail-template__tema">
          <Image
            src={CAS_DOMAIN + ultimoNumero.main_image}
            alt={ultimoNumero.titolo?.toAltTitle()}
            title={ultimoNumero.titolo?.toAltTitle()}
            className="rivista-detail-template__tema__image"
            width={200}
            height={272}
          />
          <div className="rivista-detail-template__tema__content">
            {rivista.titolo_in_evidenza && (
              <p className="rivista-detail-template__tema__label">{t('Tema del mese')}</p>
            )}
            <p className="rivista-detail-template__tema__title">{rivista.titolo_in_evidenza}</p>
            <p className="rivista-detail-template__tema__text">{rivista.testo_in_evidenza}</p>
            <Button
              className="rivista-detail-template__tema__cta"
              label={t("Scopri l'ultimo numero")}
              variant="secondary"
              href={ultimoNumero.url}
            />
          </div>
        </div>
      </div>
      <div id="cosa-contiene" className="rivista-detail-template__risorse">
        <BoxElementiRivista
          elementi={risorse.map((risorsa, index) => (
            <>
              {renderCardWithProps(risorsa, {
                position: index + 1,
                parentList: 'archivio_carosello',
              })}
            </>
          ))}
          titolo={t('Dal numero di {0} {1}', [ultimoNumero.mese, ultimoNumero.anno_solare])}
          ctaLabel={t('Vedi tutte le risorse')}
          ctaLink={`${ultimoNumero.url}risorse`}
        />
      </div>

      <div id="archivio" className="rivista-detail-template__archivio">
        <BoxElementiRivista
          elementi={numeri.map((numero, index) => (
            <NumeroRivistaCard
              numero={numero}
              key={index}
              position={index + 1}
              parentList={'archivio_carosello'}
            />
          ))}
          titolo={t('Archivio')}
          ctaLabel={t('Vedi tutti i numeri')}
          ctaLink={`${rivista.url}archivio`}
        />
      </div>
      {boxAbbonamento?.length > 0 ? (
        <div id="come-abbonarsi" className="rivista-detail-template__abbonamenti">
          <p className="rivista-detail-template__abbonati">
            {t('Abbonati a "{0}"', [rivista.titolo])}
          </p>
          <div className="rivista-detail-template__box-abbonamenti">
            {boxAbbonamento.map((box, index) => (
              <BoxAbbonamento
                box={box}
                className="rivista-detail-template__box-abbonamenti__item"
                key={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </main>
  )
}

export default RivistaDetailTemplate
