import React from 'react'
import { useTrans } from 'hooks'
import { Image } from 'react-components/atoms'
import Breadcrumbs from 'components/atomic/Breadcrumbs'
import { CAS_DOMAIN, slugify } from 'utils'
import { Button } from 'react-components/atoms'
import { RivistaCardWideNoAbbonato } from 'react-components/molecules'
import { SezioneRivista, BoxElementiRivista } from 'react-components/organisms'
import { renderCardWithProps } from 'react-components/organisms/AreaPersonaleRaccoltaContenutiEditForm/AreaPersonaleRaccoltaContenutiEditForm'

const SesamoTemplate = props => {
  const { page, rivista, risorse = [], className = '' } = props

  const t = useTrans()

  return (
    <main className={`sesamo-template ${className ? ` ${className}` : ''}`}>
      <div className="w-container sesamo-template__breadcrumbs">
        <Breadcrumbs breadcrumbs={page.breadcrumbs} />
      </div>
      <div className="w-container sesamo-template__column-display">
        <h1 className="sesamo-template__title">{rivista.titolo}</h1>
        <div className="sesamo-template__image-container">
          <Image
            src={CAS_DOMAIN + rivista.preview_image}
            alt={rivista.titolo?.toAltTitle()}
            title={rivista.titolo?.toAltTitle()}
            width={210}
            height={60}
          />
        </div>
        {rivista.sottotitolo && <p className="sesamo-template__subtitle">{rivista.sottotitolo}</p>}
        {rivista.descrizione && (
          <p className="sesamo-template__description">{rivista.descrizione}</p>
        )}
      </div>
      <div className="sesamo-template__anchors">
        {rivista.sezioni_rivista?.map(s => (
          <Button
            className="sesamo-template__anchor"
            label={s.titolo}
            variant="ghost"
            href={`#${slugify(s.titolo)}`}
            key={s.id}
          />
        ))}
        <Button
          className="sesamo-template__anchor"
          label={t('Abbonati a io+')}
          variant="ghost"
          colorVariant="orange"
          href={'#come-abbonarsi'}
        />
      </div>
      <div className="w-container" style={{ width: '100%' }}>
        <div id="in-evidenza" className="sesamo-template__tema">
          {rivista.immagine_in_evidenza && (
            <Image
              src={CAS_DOMAIN + rivista.immagine_in_evidenza}
              alt={rivista.titolo_in_evidenza?.toAltTitle()}
              title={rivista.titolo_in_evidenza?.toAltTitle()}
              className="sesamo-template__tema__image"
              width={350}
              height={235}
            />
          )}
          <div className="sesamo-template__tema__content">
            <p className="sesamo-template__tema__label">{t('Tema in evidenza')}</p>
            <p className="sesamo-template__tema__title">{rivista.titolo_in_evidenza}</p>
            <p className="sesamo-template__tema__text">{rivista.testo_in_evidenza}</p>
            <Button
              className="sesamo-template__tema__cta"
              label={t(rivista.cta_in_evidenza || 'Lasciati ispirare')}
              variant="secondary"
              href={rivista.url_in_evidenza}
            />
          </div>
          <BoxElementiRivista
            elementi={risorse.slice(0, 4).map((risorsa, index) => (
              <>{renderCardWithProps(risorsa, { position: index + 1 })}</>
            ))}
            titolo={t('Ultimi aggiornamenti')}
            className="sesamo-template__tema__risorse"
          />
        </div>
      </div>
      {rivista.sezioni_rivista?.map(s => (
        <SezioneRivista className="sesamo-template__sezione" sezione={s} rivista={rivista} />
      ))}
      <RivistaCardWideNoAbbonato
        className="sesamo-template__abbonati"
        id="come-abbonarsi"
        titolo="Abbonati a io+"
      />
    </main>
  )
}

export default SesamoTemplate
