import React, { Fragment, useRef } from 'react'

import { BreadcrumbJsonLd } from 'next-seo'
import Icon from './Icon'
import Link from 'next/link'
import SlickSlider from 'react-slick'

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
}

const Breadcrumbs = React.memo(({ breadcrumbs = [] }) => {
  if (breadcrumbs.length <= 1) {
    return <ul className="breadcrumbs"></ul>
  }

  let slider = useRef(null)

  const itemListElements = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.titolo ? item.titolo.replace(/"/g, '') : '',
    item: item.url || '/',
  }))

  const lastItem = breadcrumbs[breadcrumbs.length - 1]
  let breadcrumbsList = breadcrumbs.slice(1, -1).map((item) => (
    <Fragment key={item.titolo}>
      <li className="breadcrumbs-item">
        <Link legacyBehavior href={item.url}>
          <a>{item.titolo}</a>
        </Link>
      </li>
      <li className="breadcrumbs-separator" />
    </Fragment>
  ))

  return (
    <div className="breadcrumbs__container">
      <ul className="breadcrumbs">
        <SlickSlider ref={(self) => (slider = self)} {...settings}>
          <li className="breadcrumbs-item breadcrumbs-item--home">
            <Link legacyBehavior href="/">
              <a>
                <Icon id="home" />
              </a>
            </Link>
          </li>
          <li className="breadcrumbs-separator" />
          {breadcrumbsList}
          <li className="breadcrumbs-item breadcrumbs-item--active">{lastItem.titolo}</li>
        </SlickSlider>
        <Icon id="triangle-right" />
        <BreadcrumbJsonLd itemListElements={itemListElements} />
      </ul>
    </div>
  )
})

export default Breadcrumbs
